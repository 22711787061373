<template>
    <div class="jdt-set-table">
        <el-table
            v-loading="loading"
            :data="tableData"
            border
            style="width: 100%"
            height="700"
            tooltip-effect="light">
            <el-table-column
                prop="realName"
                align="center"
                :show-overflow-tooltip="true"
                label="姓名"
            ></el-table-column>
            <el-table-column
                prop="teamName"
                align="center"
                :show-overflow-tooltip="true"
                label="所属团队"
            ></el-table-column>
            <el-table-column
                prop="jdtC2cConversationCount"
                align="center"
                label="客户数"
            ></el-table-column>
            <el-table-column
                prop="jobCategoryMappings"
                align="center"
                label="赛道"
                :show-overflow-tooltip="true"
            ></el-table-column>
            <el-table-column
                align="center"
                label="参数值">
                <template slot-scope="scope">
                    <el-switch
                        v-model="scope.row.isShowInJDT"
                        :width="40"
                        active-color="#38bc9d"
                        inactive-color="#999999"
                        @change="setOpenStatus(scope.row)">
                    </el-switch>
                </template>
            </el-table-column>
            <template slot="empty">
                <div>
                    <span class="empty-img"></span>
                    <p class="empty-data">暂无数据</p>
                </div>
            </template>
        </el-table>
    </div>
</template>

<script>
import JdtService from '#/js/service/jdtService.js';
export default {
    props: {
        tableData: {
            type: Array
        }
    },
    data() {
        return {
            loading: false
        }
    },
    methods: {
        setOpenStatus(item) {
            if(item.userId) {
                this.loading = true;
                JdtService.setUserStatus({
                    userId: item.userId,
                    isShowInJDT: item.isShowInJDT
                }).then(res => {
                    this.loading = false;
                    shortTips("修改成功");
                }).catch(err => {
                    this.loading = false;
                    item.isShowInJDT = !item.isShowInJDT;
                })
            }
        }
    }
}
</script>

<style lang="scss" scope>
    .jdt-set-table {
        height: 60.6% !important;
        display: flex;
        flex-grow: 1;
        flex-direction: column;
        letter-spacing: 0px;
        // height: 100%;
        /deep/ .el-table {
            background-color: transparent;
            .el-table__body-wrapper {
                background-color: #fff;
            }
            th {
                padding: 0;
                height: 42px;
                line-height: 42px;
                background-color: #E8E8E8;
                border-color: #CCC;
                color: #666;
            }
            .el-table__empty-block {
                .el-table__empty-text {
                    line-height: inherit;
                }
                .empty-img {
                    display: inline-block;
                    background: url('~@src/assets/images/home/icon_no_data.svg') no-repeat;
                    width: 240px;
                    height: 228px;
                }
                .empty-data {
                    font-size: 16px;
                    line-height: 21px;
                    margin-bottom: 0px;
                }
            }
        }
    }
</style>