import { jdt as jdtUrl } from '#/js/config/api.json';
import { jdt as jdtJavaUrl } from '#/js/config/javaApi.json';

export default {
    getJdtSeting(params) {
        return _request({
            url: jdtUrl.user_jdt_seting,
            method: "GET",
            data: params
        })
    },
    setUserStatus(params) {
        return _request({
            url: jdtUrl.user_status_set.replace(/\%p/ig, params.userId),
            method: "POST",
            data: {
                isShowInJDT: params.isShowInJDT
            }
        })
    },
    getAdministratorJdtData(params) {
        return _request({
            url: jdtJavaUrl.administrator_jdt_data,
            method: "GET",
            baseURL: 'LbdJavaApi',
            javaProject: 'performance',
            data: params
        })
    },
    getJdtStatisticsData(params) {
        return _request({
            url: jdtJavaUrl.jdt_statistics_detail,
            method: "POST",
            baseURL: 'LbdJavaApi',
            javaProject: 'performance',
            data: params
        })
    }
}
