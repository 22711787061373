<template>
    <div class="user-permission-set">
        <div class="jdt-set">
            <div class="jdt-set-search">
                <el-input
                    class="name-keyword-input"
                    v-model="name"
                    :maxlength="20"
                    size="mini"
                    placeholder="搜索顾问姓名"
                    @change="search">
                    <el-button
                        slot="append"
                        type="primary"
                        icon="el-icon-search"
                        class="name-search-btn"
                        @click="search"
                    ></el-button>
                </el-input>
            </div>
            <jdt-set-table
                ref="jdtSetTable"
                :tableData="list"
            ></jdt-set-table>
            <!-- <table-page-tab
                :filterIndex="1"
                :pageTabs="pageTabJson"
            ></table-page-tab> -->
            <div class="work-table-footer">
                <el-pagination
                    :current-page="page.current"
                    :page-sizes="[20, 30, 50]"
                    :page-size="page.size"
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    class="el-pagination-workTable"
                    layout="total, sizes, prev, pager, next, slot"
                    :total="page.total">
                    <span class="pagination-text">
                        <span>前往<el-input v-model="pagerJump" @keyup.enter.native="handlePagerJump"></el-input>页</span>
                        <span @click="handlePagerJump">跳转</span>
                    </span>
                </el-pagination>
            </div>
        </div>
    </div>
</template>

<script>
import JdtSetTable from './layout/jdt-set-table.vue';
import JdtService from '#/js/service/jdtService.js';
// import TablePageTab from '#/component/workTable/table-page-tab.vue';
// import pageTabJson from './config/pageTab.json';
export default {
    name: "JdtSet",
    components: {
        JdtSetTable,
        // TablePageTab
    },
    data() {
        return {
            name: "",
            list: [],
            page: {
                total: 0,
                current: 1,
                size: 20,
            },
            pagerJump: 0,
            params: {
                take: 20,
                start: 0,
                key: ""
            }
        }
    },
    computed: {
        // pageTabJson() {
        //     if(this.$store.state.user.userInfo.isCFUser) {
        //         return pageTabJson.CFUser;
        //     }else {
        //         return pageTabJson.notCFUser;
        //     }
        // },
        isHuntingCompanySetPermission() {
            return (this.$store.state.user.userInfo.privilegeCodeList || []).includes('UserPermissionSet');
        },
    },
    // mounted() {
    //     this.getList();
    // },
    mounted() {
        if(this.$store.state.user.userInfo.isAdministrator || this.isHuntingCompanySetPermission) {
            this.loading = false;
            this.getList();
        } else {
            shortTips("权限不足，请联系管理员")
            let tabItems = JSON.parse(sessionStorage.getItem("tabItems"));
            let _index = tabItems.findIndex(item => item.path === "/jdtSet");
            tabItems.splice(_index, 1);
            sessionStorage.setItem('tabItems', JSON.stringify(tabItems));
            setTimeout(() => {
                location.href = "/Headhunting/#/";
            }, 2000)
        }
    },
    methods: {
        // 点击分页之后，让数据定位到第一条
        setScrollTop(){
            // console.log(document.getElementsByClassName('el-table__body-wrapper'))
            // console.log(document.getElementsByClassName('el-table__body-wrapper')[0].scrollTop)
            document.getElementsByClassName('el-table__body-wrapper')[0].scrollTop = 0;
        },
        // 分页
        handleSizeChange(val){
            this.page.current = 1;
            this.page.size = val;
            this.getList();
            this.setScrollTop();
        },
        handleCurrentChange(val){
            this.page.current = val;
            this.getList(true);
            this.setScrollTop();
        },
        handlePagerJump(){
            let currentPager = Number(this.pagerJump),
                currentPageTotal = Math.ceil(this.page.total/this.page.size);
            if(currentPager > 0 && currentPager <= currentPageTotal){
                this.page.current = currentPager;
                this.getList(true);
                this.setScrollTop();
            }
        },
        getList(isSilent = false) {
            let params = Object.assign({}, this.params);
            if(!isSilent) {
                this.page.current = 1;
            }
            params.take = this.page.size;
            params.start = (this.page.current - 1) * this.page.size;
            this.$refs.jdtSetTable.loading = true;
            JdtService.getJdtSeting(params).then(res => {
                this.$refs.jdtSetTable.loading = false;
                if(res) {
                    this.page.total = res.total;
                    this.list = res.list;
                } else {
                    this.page.total = 0;
                    this.list = [];
                }
            }).catch(err => {
                this.$refs.jdtSetTable.loading = false;
            })
        },
        search() {
            this.params.key = this.name;
            this.getList();
        },
    }
}
</script>

<style lang="scss" scope>
.user-permission-set {
    height: 100%;
    .jdt-set {
        height: 100%;
        min-height: 500px;
        padding: 10px 20px 0 20px;
        background: url('~@src/assets/images/home/home_bg.png') bottom center no-repeat;
        display: flex;
        flex-direction: column;
        .jdt-set-search {
            height: 52px;
            background-color: #fff;
            line-height: 52px;
            padding-left: 20px;
            .name-keyword-input {
                width: 244px;
            }
            .el-input-group__append {
                padding: 0;
                height: 28px;
                background-color: $primary;
                color: #fff;
                border-color: $primary;
                .name-search-btn {
                    padding: 0;
                    min-width: 42px;
                    margin: 0;
                    height: 26px;
                    line-height: 26px;
                }
            }
        }
        .work-table-footer {
            margin-top: 46px;
            height: 28px;
            overflow: unset;
        }
    }
}
</style>