var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "jdt-set-table" },
    [
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          staticStyle: { width: "100%" },
          attrs: {
            data: _vm.tableData,
            border: "",
            height: "700",
            "tooltip-effect": "light",
          },
        },
        [
          _c("el-table-column", {
            attrs: {
              prop: "realName",
              align: "center",
              "show-overflow-tooltip": true,
              label: "姓名",
            },
          }),
          _c("el-table-column", {
            attrs: {
              prop: "teamName",
              align: "center",
              "show-overflow-tooltip": true,
              label: "所属团队",
            },
          }),
          _c("el-table-column", {
            attrs: {
              prop: "jdtC2cConversationCount",
              align: "center",
              label: "客户数",
            },
          }),
          _c("el-table-column", {
            attrs: {
              prop: "jobCategoryMappings",
              align: "center",
              label: "赛道",
              "show-overflow-tooltip": true,
            },
          }),
          _c("el-table-column", {
            attrs: { align: "center", label: "参数值" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("el-switch", {
                      attrs: {
                        width: 40,
                        "active-color": "#38bc9d",
                        "inactive-color": "#999999",
                      },
                      on: {
                        change: function ($event) {
                          return _vm.setOpenStatus(scope.row)
                        },
                      },
                      model: {
                        value: scope.row.isShowInJDT,
                        callback: function ($$v) {
                          _vm.$set(scope.row, "isShowInJDT", $$v)
                        },
                        expression: "scope.row.isShowInJDT",
                      },
                    }),
                  ]
                },
              },
            ]),
          }),
          _c("template", { slot: "empty" }, [
            _c("div", [
              _c("span", { staticClass: "empty-img" }),
              _c("p", { staticClass: "empty-data" }, [_vm._v("暂无数据")]),
            ]),
          ]),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }